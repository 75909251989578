import React from "react";

import { Badge, Container, Checkbox } from "./../../../../..";

import css from "./style.module.scss";
import { connect } from "react-redux";

const mapStateToProps = ({ filters }) => {
  return { filters: { ...filters } };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addFilter: (payload) => dispatch({ type: `ADD_FILTER`, payload }),
    removeFilter: (payload) => dispatch({ type: `REMOVE_FILTER`, payload }),
  };
};

const CheckboxFilterGroupRaw = (props) => {
  const { categoryKey } = props;

  console.log("category key", categoryKey)
  const carrierFilters = props.filters[categoryKey]["CHECKBOX"] || new Set();

  const values = carrierFilters;

  return props.values.map((item) => {
    const attList = values[item.attribute] ? [...values[item.attribute]] : [];

    return (
      <Container
        className={`${css.checkBoxGroupContainer}`}
        key={"l" + item.label}
      >
        <Checkbox
          key={"check" + item.label}
          checked={attList.includes(item.label)}
          onChange={(e) => {
            //setItemValue(value.target.checked);
            if (e.target.checked) {
              props.addFilter({
                categoryKey,
                item,
                value: item.label,
              });
            } else {
              props.removeFilter({
                categoryKey,
                item,
                value: item.label,
              });
            }
          }}
        />
        <Container className={`${css.flex} ${css.checkBoxLabel}`}>
          {item.label}{" "}
          {item.badge && categoryKey !== "ANCILLARY" && (
            <Badge
              style={{
                height: "24px",
                padding: "2px",
                marginLeft: "4px",
                marginTop: "-1px",
              }}
              mask={item.badge.mask}
              icon={item.badge.icon?.toLowerCase()}
              color={item.badge.color}
            />
          )}
        </Container>
      </Container>
    );
  });
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CheckboxFilterGroupRaw);
