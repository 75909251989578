import React from "react";

import { Badge, Container, MinMaxSlider } from "./../../../../..";

import css from "./style.module.scss";
import { connect } from "react-redux";

const mapStateToProps = ({ filters }) => {
  return {
    filters: { ...filters },
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addFilter: (payload) => dispatch({ type: `ADD_FILTER`, payload }),
    removeFilter: (payload) => dispatch({ type: `REMOVE_FILTER`, payload }),
  };
};

const MinMaxFilterGroupRaw = (props) => {
  const { categoryKey } = props;

  console.log("carrier keys",props)
  const carrierFilters =
    props.filters[categoryKey]["MIN_MAX_SLIDER"] || new Set();

  const values = carrierFilters;

  return props.values.map((item) => {
    const v = values[item.attribute] ? values[item.attribute] : {};

    return (
      <MinMaxSlider
        onChange={(e) => {
          //setItemValue(value.target.checked);

          props.addFilter({
            categoryKey,
            item,
            value: e,
          });
        }}
        key={"min_max" + item.label}
        label={item.label}
        min={item.min}
        max={item.max}
        value={[v.min, v.max]}
      />
    );
  });
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MinMaxFilterGroupRaw);
