import axios from "axios";
import React, { useRef } from "react";
import { useState } from "react";
import { ModalRaw } from "../modal";

interface FindADoctorInputProps {
  handleFilterDoctor: (doctorNpi: string) => Promise<void>;
  handleRemoveDoctor: () => void;
  zipCode: string;
}

const SearchIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      stroke-width="1.5"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M15.5 15.5L19 19"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 11C5 14.3137 7.68629 17 11 17C12.6597 17 14.1621 16.3261 15.2483 15.237C16.3308 14.1517 17 12.654 17 11C17 7.68629 14.3137 5 11 5C7.68629 5 5 7.68629 5 11Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const CloseIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      stroke-width="1.5"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M6.75827 17.2426L12.0009 12M17.2435 6.75736L12.0009 12M12.0009 12L6.75827 6.75736M12.0009 12L17.2435 17.2426"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

import css from "./style.module.scss";

export const FindADoctorInput = ({
  handleFilterDoctor,
  handleRemoveDoctor,
  zipCode,
}: FindADoctorInputProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filteredDoctor, setFilteredDoctor] = useState("");
  const [doctorList, setDoctorList] = useState([]);
  const [doctorText, setDoctorText] = useState("");

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const fetchDoctorList = async (e: React.FormEvent) => {
    e.preventDefault();

    const response = await axios({
      method: "get",
      url: `https://api.qa.myaspirequotes.com/v1/search-providers/marketplace`,
      params: {
        zip_code: zipCode,
        search_query: doctorText,
      },
    });

    const { providers } = response.data;

    setDoctorList(providers);

    handleOpenModal();
  };

  const handleChangeDoctor = (searchText?: string, doctorName?: string) => {
    if (filteredDoctor) {
      handleRemoveDoctor();
      setFilteredDoctor("");
    } else {
      setFilteredDoctor(doctorName);
      setDoctorText(searchText);
    }
  };

  return (
    <>
      <div className={css.findADoctorContainer}>
        <form className={css.inputContainer} onSubmit={fetchDoctorList}>
          <SearchIcon />
          <input
            type="text"
            placeholder="Enter the name of a doctor, specialist"
            value={doctorText}
            onChange={(e) => setDoctorText(e.currentTarget.value)}
          />
          <button type="submit">Find a Doctor</button>
        </form>
        {filteredDoctor && (
          <button
            onClick={() => handleChangeDoctor()}
            className={css.doctorFilter}
          >
            <div>
              {filteredDoctor}
              <div className={css.closeIcon}>
                <CloseIcon />
              </div>
            </div>
          </button>
        )}
      </div>
      <ModalRaw
        currentDoctor={doctorText}
        handleChangeDoctor={handleChangeDoctor}
        isOpen={isModalOpen}
        handleSelectDoctor={handleFilterDoctor}
        onClose={handleCloseModal}
        doctorList={doctorList}
        zipCode={zipCode}
      />
    </>
  );
};
