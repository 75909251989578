import React, { useState } from "react";

import { Button, Container, Icon } from "../../.."

import css from "./style.module.scss"

import MiniSection from "../mini-section";

export default (props) => { 
    let getParams: any = () => {
        try {
            return document.location.search
                .substring(1)
                .split("&")
                .reduce((old, current) => {
                    const [key, value] = current.split("=");
                    value && (old[key] = value.replace("%20", " "));
                    return old;
                }, {});
        } catch (e) {
            return {};
        }
    };
    const applicants = [];

    const params = getParams();
    const spouse = params.spouse
        ? JSON.parse(decodeURIComponent(params.spouse))
        : null;
    const dependents = params.dependents
        ? JSON.parse(decodeURIComponent(params.dependents))
        : [];


    applicants.push({
        label: "Primary",
        age: params.age,
        gender: params.gender,
        uses_tobacco: params.uses_tobacco =="true" ? true : false
    })

    if (spouse) applicants.push({ ...spouse, label: "Spouse" });

    dependents.forEach(d => {
        applicants.push({ ...d, label: "Dependent"  })
    })

    const ApplicantRow = ({ label, age, gender, tobacco }) => {
        return <tr className={css.applicantRow}>
            <td className={css.applicantLabel}>{label}</td>
            <td className={css.applicantAge}>{age}  </td>
            <td>{<Icon fill={gender == "Male" ? "#27A0FF" : "#ec09b7"} icon={gender == "Male" ? "male" : "female"} />} </td>
            <td><Icon fill={tobacco ? "#605C5C" : "#FF3D3D"} icon={tobacco ? "tobacco" : "dont-tobacco"} /> </td>
        </tr>
    }

    
    if(props.mobile) {
        return <table className={`${css.mobileTable}`}>
            <thead>
                <tr className={`${css.applicantsHeaders}`}>
                    <th></th><th>Age</th><th>Gender</th><th>Tobacco</th>
                </tr>
            </thead>
            <tbody>
                {applicants?.map(a => <ApplicantRow label={a.label} age={a.age} tobacco={a.uses_tobacco} gender={a.gender} />)}
            </tbody>
        </table>
    }

    return <MiniSection openByDefault title={`Applicants (${applicants?.length})`}>

    <table>
        <thead>
            <tr className={css.applicantsHeaders}>
                <th></th><th>Age</th><th>Gender</th><th>Tobacco</th>
            </tr>
        </thead>
        <tbody>
            {applicants?.map(a => <ApplicantRow key={ "app-" + a.label} label={a.label} age={a.age} tobacco={a.uses_tobacco} gender={a.gender} />)}
        </tbody>
    </table>

</MiniSection> }


