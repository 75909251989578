import React, { useState, useEffect } from "react";
import {
  Badge,
  Checkbox,
  Container,
  ShareAltOutlined,
  Icon,
  Tooltip,
  Select,
} from "../../..";
import { Button, Popover, Radio, Space, Spin, Skeleton } from "antd";
import { formatCurrency } from "../../../../utils";
import BenefitsList from "./benefits-list";

import css from "./style.module.scss";

import axios from "axios";

import { connect } from "react-redux";

export const metalLevelColors = {
  Bronze: "#6A3805",
  Catastrophic: "#575988",
  Gold: "#AF9500",
  Platinum: "#3B3B41",
  Silver: "#B4B4B4",
};

const PrefferedRateComponent = (props) => {
  const [popupVisible, setPopupVisible] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [priorCoverage, setPriorCoverage] = useState(props.priorCoverage);
  const [prefferedRate, setPrefferedRate] = useState(props.preferredRate);
  const [loading, setLoading] = useState(true);

  return (
    <Container className={`${css.prefferedRate}  `}>
      <Popover
        placement="bottom"
        trigger="click"
        title={
          <Container
            style={{
              display: "flex",
              paddingLeft: "12px",
              justifyContent: "space-between",
            }}
          >
            Check if you qualify for a lower premium
            <Container
              onPress={() => {
                setPopupVisible(false);
              }}
            >
              X
            </Container>
          </Container>
        }
        visible={popupVisible}
        onVisibleChange={(visible) => {
          setPopupVisible(visible);
        }}
        content={
          <Container className={css.popoverContent}>
            {/* <Container className={ css.popupInputsContainer}>
          <Container className={css.popupInputContainer}>
            <Container className={css.popupInputLabel}>Prior Coverage</Container>
          <Container>
            <Select options={ [{
              label:"yes",
              value: "yes",

            },
            { label:"no", value:"no"}
            ]}/>
          </Container>

        </Container>
          <Container className= { css.popupInputContainer}>
            <Container className={css.popupInputLabel}>Preffered Premium</Container>
            <Container> <Checkbox/></Container>

          </Container>
      </Container> */}
            <Container>
              <Container className={css.popupDescription}>
                <Container className={css.popupDescriptionLabel}>
                  Prior Coverage
                </Container>

                <Container
                  className={`${css.popupDescriptionText}  ${
                    priorCoverage !== undefined ? css.questionOk : ""
                  } `}
                >
                  All adult applicants had other major medical health insurance
                  (including Short Term) for at least 9 months within the last
                  12 months
                  <br />
                  <br />
                  Do you have prior coverage?
                </Container>

                <Container className={css.popupRadio}>
                  <Radio.Group
                    value={priorCoverage}
                    onChange={async (v) => {
                      const baseUrl =
                        props.siteData?.site?.siteMetadata?.baseUrl;
                      console.log("v", props);
                      setPriorCoverage(v.target.value);

                      if (
                        (v.target.value && props.uses_tobacco !== "true") ||
                        props.spouse_uses_tobacco == "true"
                      ) {
                        try {
                          setLoading(true);
                          const response = await axios({
                            method: "get",
                            url: `${baseUrl}/v1/enrollment/national-general/${props.quote_id}?state=${props.state}&plan_id=${props.plan_id.value}&preferred=true`,
                            headers: {
                              "x-apollo-quotes-source":
                                props.siteData?.site?.siteMetadata?.agent?.id,
                              baseUrl,
                            },
                          });

                          const questions = response.data?.applications[0]?.generalQuestions?.map(
                            (q) => q.questionText
                          );
                          questions.shift();
                          setQuestions(questions || []);
                        } finally {
                          setLoading(false);
                        }
                      } else {
                      }
                    }}
                    buttonStyle="solid"
                    size="large"
                  >
                    <Space>
                      <Radio value={1}>Yes</Radio>
                      <Radio value={0}>No</Radio>
                    </Space>
                  </Radio.Group>
                </Container>
              </Container>
            </Container>
            <Container>
              {priorCoverage === 1 &&
                props.uses_tobacco !== "true" &&
                (loading ? (
                  <Container className={css.spin}>
                    {" "}
                    <Spin />{" "}
                  </Container>
                ) : (
                  <Container className={css.popupDescription}>
                    {priorCoverage == 1 && !prefferedRate && (
                      <Container className={css.discount}>
                        <span>
                          $
                          {props.premium_options
                            ? props.premium_options.options[1].premium
                            : 0}
                        </span>{" "}
                        rate available
                      </Container>
                    )}
                    <Container className={css.popupDescriptionLabel}>
                      Preferred Premium
                    </Container>
                    <Container className={css.popupDescriptionSection}>
                      <Container
                        className={`${css.popupDescriptionText}  ${
                          prefferedRate !== undefined ? css.questionOk : ""
                        } `}
                      >
                        -All adult applicants had other major medical health
                        insurance (including Short Term) for at least 9 months
                        within the last 12 months.
                      </Container>
                      <Container
                        className={`${css.popupDescriptionText}  ${
                          prefferedRate !== undefined ? css.questionOk : ""
                        } `}
                      >
                        -All the male applicants must weight 240 pounds or less
                        and all female applicants must weight 190 pounds or
                        less.
                      </Container>
                      <Container
                        className={`${css.popupDescriptionText}  ${
                          prefferedRate !== undefined ? css.questionOk : ""
                        } `}
                      >
                        -You have to answer <span>no</span> to the following
                        questions{" "}
                      </Container>
                      <Container>
                        {questions.map((q) => {
                          return (
                            <Container
                              style={{ margin: "12px", display: "flex" }}
                            >
                              <Container style={{ marginRight: "6px" }}>
                                •
                              </Container>
                              <div dangerouslySetInnerHTML={{ __html: q }} />
                            </Container>
                          );
                        })}
                      </Container>

                      <Container
                        className={`${
                          prefferedRate !== undefined ? css.questionOk : ""
                        } `}
                      >
                        Do you qualify for a preferred premium?
                      </Container>
                      <Container style={{ marginBottom: "12px" }} />
                      <Container className={css.popupRadio}>
                        <Radio.Group
                          value={prefferedRate}
                          onChange={(v) => {
                            setPrefferedRate(v.target.value);
                          }}
                          buttonStyle="solid"
                          size="large"
                        >
                          <Space>
                            <Radio value={1}>Yes</Radio>
                            <Radio value={0}>No</Radio>
                          </Space>
                        </Radio.Group>
                      </Container>
                    </Container>
                    {priorCoverage == 1 && prefferedRate == 1 && (
                      <Container className={css.discount}>
                        <span>
                          ${" "}
                          {priorCoverage
                            ? prefferedRate
                              ? props.premium_options.lowest_premium
                              : props.premium_options.options[1].premium
                            : 0}
                        </span>{" "}
                        rate available
                      </Container>
                    )}
                  </Container>
                ))}
            </Container>
            <Container
              onPress={() => {
                props.onPreferredRateSubmit &&
                  props.onPreferredRateSubmit({
                    priorCoverage,
                    prefferedRate,
                  });
                setPopupVisible(false);
              }}
              className={css.submitButton}
            >
              <Button type="primary"> Submit</Button>
            </Container>
          </Container>
        }
      >
        <Container
          onPress={() => {
            setPopupVisible(true);
          }}
        >
          {props.specialRates &&
          (props.specialRates.priorCoverage == 1 ||
            props.specialRates.prefferedRate == 1) ? (
            <Container>
              $
              {props.specialRates.prefferedRate
                ? props.premium_options.lowest_premium
                : props.premium_options.options[1].premium}{" "}
              premium available.
            </Container>
          ) : (
            <Container>
              You may qualify for a $
              {props.premium_options
                ? props.uses_tobacco
                  ? props.premium_options.options[1].premium
                  : props.premium_options.lowest_premium
                : 0}{" "}
              premium.
            </Container>
          )}
        </Container>
      </Popover>
    </Container>
  );
};

export const OfferCardSkeleton = () => {
  return (
    <Container className={css.offerCardContainer}>
      <Container
        style={{ borderColor: "#EEE", padding: "24px" }}
        className={css.infoContainer}
      >
        <Skeleton title={false} paragraph={{ rows: 4, width: "100%" }} active />
      </Container>
    </Container>
  );
};

const OfferCardRaw = (props) => {
  const {
    find_doctor_url,
    addForComparison,
    removeFromComparison,
    comparing,
    plan_premium_only,
    benefits,
    hideDeductible,
    hideHeader,
    metal_level,
    innerStyle,
    style,
    score,
    carrier,
    imgid,
    exportImage,
    pdf,
    onSelectChange,
    deductible,
    id,
    plan_name,
    premium,
    selected,
    info,
    showSelection,
    category,
  } = props;
  let iconProps = {};

  if (info && info.label == "Metal Level")
    iconProps = { icon: "star-flag", fill: metalLevelColors[info.value] };
  if (info && info.value == "Association")
    iconProps = { icon: "association", fill: "#F7971C" };
  if (info && info.value == "NHICSupplemental")
    iconProps = { icon: "shield-filled", fill: "#F7971C" };
  if (info && info.value == "Short Term")
    iconProps = { icon: "shield-outlined", fill: "#F7971C" };
  if (info && info.value == "Basic")
    iconProps = { icon: "s-outlined", fill: "#1B75BC" };
  if (info && info.value == "Crown")
    iconProps = { icon: "crown", fill: "#1B75BC" };
  if (info && info.value == "Enhanced")
    iconProps = { icon: "s-filled", fill: "#1B75BC" };
  if (info && info.value == "Short Term Health Insurance")
    iconProps = { fill: "#575988", icon: "short-term" };
  if (info && info.value == "Dental Insurance")
    iconProps = { icon: "dental-insurance", fill: "#053555" };
  if (info && info.value == "HealthiestYou")
    iconProps = { icon: "healthiest-you", fill: "#77C3FF" };
  if (info && info.value == "Hospital Indemnity")
    iconProps = { icon: "hospital-indemnity", fill: "#FF3D3D" };
  if (info && info.value == "Supplemental Indemnity")
    iconProps = { icon: "supplemental", fill: "#F3BF38" };
  if (info && info.value == "HealthiestYou")
    iconProps = { icon: "healthiest-you", fill: "#77C3FF" };
  if (info && info.value == "Term Life")
    iconProps = { icon: "term-life", fill: "#1B75BC" };
  if (info && info.value == "Vision")
    iconProps = { icon: "vision", fill: "#1C4C99" };
  if (info && info.value == "Accident")
    iconProps = { icon: "accident", fill: "#F7971C" };
  if (info && info.value == "Critical Illness")
    iconProps = { icon: "critical-illness", fill: "#77C3FF" };
  if (info && info.value == "ACSM")
    iconProps = { icon: "universal-acsm", fill: "#fa6647" };
  if (info && info.value == "Standard")
    iconProps = { icon: "universal", fill: "#FCBF45" };
  if (info && info.value == "SmartShare")
    iconProps = { icon: "universal-smart-share", fill: "#212282" };
  if (plan_name.value == "Aspire Dental")
    iconProps = { icon: "aspire-dental", fill: "#77C3FF" };
  if (category == "ANCILLARY") {
    iconProps = { icon: "no-icon", fill: "#fffff" }
  }
  return (
    <Container
      style={style}
      id={imgid}
      className={`${css.offerCardContainer} ${pdf ? css.pdf : css.screen}`}
    >
      <Container
        style={innerStyle}
        className={`${css.infoContainer} ${
          pdf ? css.infoContainerScreenOnly : ""
        } ${
          score
            ? score > 50
              ? css.green
              : score > 25
              ? css.yellow
              : css.red
            : ""
        }`}
      >
        {!hideHeader && (
          <Container
            className={`${css.planNames} ${
              selected ? css.planNamesSelected : ""
            }`}
          >
            <Tooltip title={carrier.value}>
              <Container className={css.issuerName}>
                {carrier.value} {score ? `(score: ${"" + (100 - score)})` : ``}
              </Container>
            </Tooltip>
            <Container className={css.planName}>
              <Tooltip title={plan_name.value}>{plan_name.value}</Tooltip>
            </Container>
            {category !== "MAJOR_MEDICAL" && find_doctor_url && (
              <a
                href={find_doctor_url}
                className={css.findADoctorLink}
                target="_blank"
              >
                <DoctorIcon />
                <span>Find a Doctor</span>
              </a>
            )}
          </Container>
        )}

        <Container className={css.info2}>
          {
            <Icon
              style={{
                ...(hideHeader ? { transform: "translateY(-90px)" } : {}),
              }}
              {...iconProps}
            />
          }

          <Container className={`${css.planTypePrice} ${css.marginRight24px}`}>
            <Container
              className={css.planCategory}
              style={{ color: iconProps.fill }}
            >
              {info?.value}
            </Container>

            {false && (
              <Container className={css.planType}>{premium.label}</Container>
            )}
            <Container
              className={`${css.planPrice} ${
                props.specialRates &&
                info &&
                info.value == "Short Term" &&
                props.specialRates.priorCoverage == 1
                  ? css.priorCoverage
                  : ""
              } `}
            >
              {formatCurrency(premium.value) + " / Month"}
              {premium.premium_without_subsidy && (
                <Container className={css.premiumWithoutSubsidy}>
                  $ {premium.premium_without_subsidy}
                </Container>
              )}
            </Container>
          </Container>
        </Container>
        {carrier.value == "National General" && info && info.value == "Short Term" && (
          <PrefferedRateComponent {...props} />
        )}
        {!pdf && (
          <Container>
            <BenefitsList
              plan={props}
              pdf={pdf}
              benefits={benefits}
              id={imgid}
            />
          </Container>
        )}
        {!pdf && (
          <Container className={css.info3}>
            {info && deductible ? (
              <Container className={css.info3}>
                {deductible.label} {deductible.value ? ":" : ""}
                <Container className={css.metalLevelText}>
                  {" "}
                  {deductible.value && formatCurrency(deductible.value)}
                </Container>
              </Container>
            ) : (
              <Container className={css.info3}>
                <Badge
                  style={{ height: "18px", marginTop: "4px" }}
                  color={"#fff"}
                />
              </Container>
            )}
          </Container>
        )}

        {carrier.value == "National General" &&
          props.fees?.MONTHLY?.fees_details[0]?.optional && (
            <Container style={{ margin: "auto", marginBottom: "12px" }}>
              {" "}
              <Checkbox
                onChange={(e) =>
                  props.onAssociationFeeChange &&
                  props.onAssociationFeeChange(e)
                }
                checked={props.associationFee}
              />{" "}
              Include Association Membership{" "}
            </Container>
          )}
        {!pdf && onSelectChange && (
          <Container className={css.addButtons}>
            <Container
              onClick={() => {
                comparing ? removeFromComparison(id) : addForComparison(id);
              }}
              className={`${css.compareButtonContainer} ${
                comparing ? css.comparing : ""
              }`}
            >
              <Container className={css.compareButtonText}>
                Compare Plan
              </Container>
              <Container>
                <Icon
                  className={css.compareButtonIcon}
                  icon="compare-plan"
                  fill="#fff"
                />
              </Container>
            </Container>
            <Container
              id={("add-to-cart-btn" + imgid + "-" + carrier.value).replace(
                " ",
                "-"
              )}
              onPress={() => {
                onSelectChange &&
                  onSelectChange({
                    id,
                    checked: !selected,
                  });
              }}
              className={`${css.info4} ${selected ? css.selected : ""}`}
            >
              {/* Interested
            {selected ? <HeartFilled /> : <HeartOutlined />} */}
              {selected ? (
                <Icon
                  style={{ marginLeft: "6px", width: "36px", height: "36px" }}
                  fill={"#06315E"}
                  icon="remove-from-cart"
                />
              ) : (
                <Icon
                  style={{ marginLeft: "6px", width: "36px", height: "36px" }}
                  fill={"#00759A"}
                  icon={"add-to-cart"}
                />
              )}
              {selected ? "Remove" : "Add to Cart"}
            </Container>
          </Container>
        )}
      </Container>
    </Container>
  );
};

const DoctorIcon = () => {
  return (
    <svg
      width="41"
      height="41"
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_3328_808)">
        <path
          d="M29.8986 1.95312H25.4944V4.67738H28.5365V10.7616C28.5365 15.3433 24.8088 19.0706 20.2275 19.0706H19.5918C15.0101 19.0706 11.2829 15.3428 11.2829 10.7616V4.67738H14.3249V1.95312H9.92072C9.16839 1.95312 8.55859 2.56292 8.55859 3.31525V10.7616C8.55859 16.8453 13.5081 21.7948 19.5918 21.7948H20.2275C26.3112 21.7948 31.2607 16.8453 31.2607 10.7616V3.31525C31.2607 2.56292 30.6509 1.95312 29.8986 1.95312Z"
          fill="#fff"
        />
        <path
          d="M25.3582 0C24.6059 0 23.9961 0.609795 23.9961 1.36213V5.17609C23.9961 5.92842 24.6059 6.53822 25.3582 6.53822C26.1106 6.53822 26.7204 5.92842 26.7204 5.17609V1.36213C26.7204 0.609795 26.1106 0 25.3582 0Z"
          fill="#fff"
        />
        <path
          d="M14.5965 0C13.8442 0 13.2344 0.609795 13.2344 1.36213V5.17609C13.2344 5.92842 13.8442 6.53822 14.5965 6.53822C15.3488 6.53822 15.9586 5.92842 15.9586 5.17609V1.36213C15.9586 0.609795 15.3488 0 14.5965 0Z"
          fill="#fff"
        />
        <path
          d="M21.363 28.1054V20.3867H18.6387V28.1054H14.2345C10.6794 28.1054 7.78711 30.9977 7.78711 34.5524C7.78711 38.1075 10.6998 40.9998 14.2799 40.9998H14.9156C18.4707 40.9998 21.363 38.1075 21.363 34.5524V30.8297H25.9942V28.1054H21.363ZM18.6387 34.5519C18.6387 36.6046 16.9683 38.275 14.9156 38.275H14.2799C12.1668 38.275 10.5114 36.64 10.5114 34.5519C10.5114 32.4992 12.1818 30.8292 14.2345 30.8292H18.6387V34.5519Z"
          fill="#fff"
        />
        <path
          d="M28.9453 25.1992C26.5924 25.1992 24.6777 27.1135 24.6777 29.4672C24.6777 31.8201 26.592 33.7347 28.9453 33.7347C31.2986 33.7347 33.2133 31.8201 33.2133 29.4672C33.2133 27.1139 31.299 25.1992 28.9453 25.1992ZM28.9453 31.0101C28.0943 31.0101 27.402 30.3177 27.402 29.4668C27.402 28.6155 28.0944 27.9231 28.9453 27.9231C29.7966 27.9231 30.489 28.6155 30.489 29.4668C30.489 30.3177 29.7966 31.0101 28.9453 31.0101Z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="clip0_3328_808">
          <rect width="41" height="41" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => {
  return {
    addForComparison: (payload) => {
      dispatch({ type: `ADD_FOR_COMPARISON`, payload });
    },
    removeFromComparison: (payload) => {
      dispatch({ type: `REMOVE_FROM_COMPARISON`, payload });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OfferCardRaw);
