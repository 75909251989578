import React, { useState } from "react";

import css from "./style.module.scss";

import {
  Container,
  Slider,
  DollarTwoTone,
  ArrowDownOutlined,
  ArrowUpOutlined,
} from "../../";
import { connect } from "react-redux";
import { formatCurrency } from "../../../utils";

export const MinMaxSliderRaw = (props) => {
  const marks = {};

  const [value, setValue] = useState([props.min, props.max]);

  marks[props.min] = marks[props.max] = {
    label: `${formatCurrency(props.min)}`,
    style: {
      transform: "translateX(20%)",
      fontFamily: "Poppins"
    },
  };

  marks[props.max] = {
    label: `${formatCurrency(props.max)}`,
    style: {
      transform: "translateX(-110%)",
      whiteSpace: "nowrap",
      fontFamily: "Poppins"
    },
  };

  // TODO style
  return (
    <Container
      style={{ ...(props.label == "Premium" ? { marginBottom: "12px" } : {}) }}
      className={`${css.items} `}
    >
      <Container className={css.priceRangeCardInfo}>
        <Container
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Container style={{ fontWeight: 500, color: "#333", fontFamily: "Poppins", transform: "translateX(6px)" }} className={css.priceRangeCardInfoTitle}>{props.label}</Container>
          <Container>
            <ArrowDownOutlined
              onClick={() => {
                props.onSort({
                  by: props.label,
                  order: "asc",
                });
              }}
              className={`link ${
                props.sort.by == props.label && props.sort.order == "asc"
                  ? "active"
                  : ""
              }`}
            />{" "}
            <ArrowUpOutlined
              onClick={() => {
                props.onSort({
                  by: props.label,
                  order: "desc",
                });
              }}
              className={`link ${
                props.sort.by == props.label && props.sort.order == "desc"
                  ? "active"
                  : ""
              }`}
            />
          </Container>
        </Container>
        {false && (value[0] != props.min || value[1] != props.max) && (
          <Container className={css.priceRangeFilters}>
            <Container className={css.minMaxContainer}>
              <Container className={css.min}>
                ${value[0]} to ${value[1]}
              </Container>
              {/* <Container className={css.max}> to: ${value[1]}</Container> */}
            </Container>
          </Container>
        )}
      </Container>
      <Container className={css.rangeSliderContainer}>
        {true && <DollarTwoTone className={css.rangeIcon} />}
        <Slider
          marks={marks}
          onChange={(v) => {
            setValue(v);

            props.onChange && props.onChange(v);
          }}
          value={value}
          tipFormatter={props.tipFormatter}
          min={props.min}
          max={props.max}
          className={css.rangeSlider}
          range
        />
      </Container>
    </Container>
  );
};

const mapStateToProps = ({ sort }) => {
  return {
    sort,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSort: (payload) =>
      dispatch({
        type: "SORT",
        payload,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MinMaxSliderRaw);
