import React, { useState, useEffect } from "react";
import { Accordeon, Container, Badge } from "../../..";
import css from "./style.module.scss";
import FilterGroup from "./filter-group";
import { ApolloCollors } from "../../../rules/design/apollo-colors";
import { v4 as uuid } from "uuid";
import Sorter from "./sorter";

const buildFilters = (items, label, props) => {
  let categoryKey;

  categoryKey = label.replace(" ","_").toUpperCase();

  if (label == "General") categoryKey = "ALL";

  return (
    <>
      {items.map((item) => {
        if (item.type === "GROUP") {
          return (
            <FilterGroup
              categoryKey={categoryKey}
              onChange={(i, v) => {
                props.onChange && props.onChange(label, i, v);
              }}
              key={label + item.type + item.attribute}
              {...item}
            />
          );
        } else return null;
      })}
    </>
  );
};

const FilterAccordeon = ({ activeTab, facetItem, props }) => {
  const [collapsed, setCollapsed] = useState(
    !(facetItem.label == "General" || facetItem.label == props.activeTab)
  );

  useEffect(() => {
    setCollapsed(
      !(facetItem.label == "General" || facetItem.label == props.activeTab)
    );
  }, [activeTab]);

  if (
    // facetItem.label != "Major Medical" &&
    // facetItem.label != "Short Term" &&
    // facetItem.label != "Vision" &&
    // facetItem.label != "Dental" &&
    // facetItem.label != "Ancillary" &&
    // facetItem.label != "Health Share" &&
    // facetItem.label != "General"
    facetItem.label != activeTab
  ) {
    return null;
  }

  return (

      buildFilters(facetItem.values, facetItem.label, props)

  );
};

export default (props) => {
  return (
    <Container className={css.searchFiltersContainer}>
      {props.data.map((facetItem) => {
        if (
          facetItem.type === "GROUP" &&
          facetItem.values[0] &&
          facetItem.values[0].values &&
          facetItem.values[0].values.length > 0
        ) {
          return (
            <FilterAccordeon
              props={props}
              key={"group" + facetItem.label + "f"}
              activeTab={props.activeTab}
              facetItem={facetItem}
            />
          );
        }
      })}
    </Container>
  );
};
