import React from "react";
import { Container, Icon } from "..";
import css from "./style.module.scss";

export default (props) => (
  <Container {...props} className={`${css.avatarContainer} ${props.className}`}>
    <Container className={css.avatarInnerContainer}>
      {props.initials ? (
        <Container className={css.initials}>{props.initials}</Container>
      ) : (
        <img
          className={`${props.round ? css.round : ""} ${
            props.border ? css.border : ""
          }`}
          src={props.src || "/default_avatar.png"}
        />
      )}
    </Container>
  </Container>
);
