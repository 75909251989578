import React, { useState, useEffect } from "react";
import {
  Container,
  ExclamationOutlined,
  FilePdfOutlined,
  SpinnerPanel,
} from "../../..";
import OfferCard, { OfferCardSkeleton } from "../offer-card";
import { exportPDF } from "../../pdf-util";
import css from "./style.module.scss";

import { exportAsImage, render } from "../../../../utils";

import { graphql, useStaticQuery } from "gatsby";
import { v4 as uuidv4 } from "uuid";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import Lottie from "react-lottie-player";
import lottieJson from "../../../../lotties/quotes-progress.json";

import { connect } from "react-redux";
import { Empty } from "antd";

let agent;

const OfferCardListSkeleton = () => (
  <Container className={css.offerCardListContainer}>
    <Container className={css.quoteLoadingAnimation}>
      <Lottie loop animationData={lottieJson} play className={css.lottie} />
      <Container className={css.loadingText}>
        Please wait...
        <br />
        <span>The best health options are on the way</span>
      </Container>
    </Container>
  </Container>
);

const OfferCardListRaw = (props) => {
  if (props.loading) {
    return <OfferCardListSkeleton />;
  }

  const { data } = props;

  agent = {
    name: data.site.siteMetadata.agent.name,
    title: data.site.siteMetadata.agent.title,
    email: data.site.siteMetadata.agent.email,
    website: data.site.siteMetadata.agent.website,
    phone: data.site.siteMetadata.agent.phone,
    image: data.site.siteMetadata.agent.assets.image,
  };

  const { filters } = props;

  const filterItems = (items) => {
    let filteredOffers = items;
    // if (filters.carriers.length > 0) {
    //     filteredOffers = filteredOffers.filter(i => filters.carriers.includes(i.issuer.name))
    // }

    // if (filters.metalLevels.length > 0) {
    //     filteredOffers = filteredOffers.filter(i => filters.metalLevels.includes(i.metal_level))
    // }

    // if (filters.priceRange ?.Premiums ?.value[0] ||
    //     filters.priceRange ?.Premiums ?.value[1]) {
    //     filteredOffers = filteredOffers.filter(i =>
    //         i.premium >= filters.priceRange ?.Premiums ?.value[0] &&
    //             i.premium <= filters.priceRange ?.Premiums ?.value[1]
    //     )
    // }

    // if (
    //     filters.priceRange ?.Deductibles ?.value[0] ||
    //         filters.priceRange ?.Deductibles ?.value[1]
    // ) {
    //     filteredOffers = filteredOffers.filter(i =>
    //         i.deductibles[0].amount >= filters.priceRange ?.Deductibles ?.value[0] &&
    //             i.deductibles[0].amount <= filters.priceRange ?.Deductibles ?.value[1]
    //     )
    // }

    return filteredOffers;
  };

  const filteredOffers = filterItems(props.items);

  if (filteredOffers.length == 0) {
    return (
      <Container style={{ marginTop: "180px" }}>
        <Empty description="No Results" />
      </Container>
    );
  }

  return (
    <Container className={`${css.offerCardListContainer2}`}>
      {filteredOffers[0].carrier.value == "Universal" && (
        <Container className={css.warning}>
          <ExclamationOutlined className={css.exclamationIcon} /> The selections
          below are health care cost sharing options and are not health
          insurance.
        </Container>
      )}
      <ResponsiveMasonry columnsCountBreakPoints={{ 500: 1, 1400: 2, 1800: 3 }}>
        <Masonry gutter="36px">
          {filteredOffers.map((i, ind) => (
            <OfferCard
              find_doctor_url={props.find_doctor_url}
              comparing={props.itemsForComparison.has(i.id)}
              associationFee={props.associationFee}
              onAssociationFeeChange={props.onAssociationFeeChange}
              quote_id={props.quote_id}
              state={props.state}
              uses_tobacco={props.uses_tobacco}
              key={uuidv4()}
              showSelection={false}
              imgid={"card" + ind}
              siteData={data}
              onPreferredRateSubmit={props.onPreferredRateSubmit}
              specialRates={props.specialRates}
              exportImage={async () => {
                const imageContainer = document.createElement("div");

                imageContainer.setAttribute(
                  "style",
                  `width:600px;height:324px;display: flex;flex-direction:column;justify-content:space-between; align-items: center;padding-bottom: 12px;`
                );

                const card = document.getElementById("card" + ind);

                const headerElement = document.createElement("div");
                headerElement.setAttribute("id", "card" + ind + "header");
                document.body.append(headerElement);

                const header = document.createElement("div");

                await render(
                  <div className={`${css.headerStyle}`}>
                    <div>
                      <img
                        className={css.imageStyle}
                        src={data.site.siteMetadata.agent.assets.image}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "12px",
                      }}
                    >
                      <div className={`${css.defaultStyle} ${css.nameStyle}`}>
                        {agent.name}
                      </div>
                      <div className={`${css.defaultStyle} ${css.titleStyle}`}>
                        {agent.title}
                      </div>
                      <div
                        className={`${css.defaultStyle} ${css.underscoreStyle}`}
                      />
                      <div className={`${css.defaultStyle}`}>{agent.email}</div>
                      <div className={`${css.defaultStyle}`}>
                        {agent.website}
                      </div>
                      <div className={`${css.defaultStyle}`}>{agent.phone}</div>
                    </div>
                    <div style={{ flexGrow: 1 }} />
                    <div className={css.qrCodeStyle}>
                      <canvas className={"replace-with-qr-code"} />
                    </div>
                  </div>,
                  header
                );

                imageContainer.innerHTML = `
                        
                        ${header.innerHTML}
                        
                        
                        <div style="width:480px" ${card.innerHTML.substring(4)}
                    
                    `;

                exportAsImage("quotes", "jpg", imageContainer);
              }}
              key={i.id}
              {...i}
            />
          ))}
        </Masonry>
      </ResponsiveMasonry>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    itemsForComparison: state.itemsForComparison,
  };
};

export default connect(mapStateToProps)(OfferCardListRaw);
