import React , { useState, useEffect } from "react";

import { Button, Container, Icon, TextInput } from "../../.."

import css from "./style.module.scss"

import MiniSection from "../mini-section";
import CompareMiniCard from "../compare-mini-card";

import axios from "axios";
import { connect } from "react-redux"

import { Spin } from "antd";

import agents from "../profile/agents"

const CompareMiniSection = (props) => {

    const { baseUrl, clearComparisonList, itemsForComparison } = props;

    if(!itemsForComparison) return null;
    
    
    const agent = props.selectedAgent ? agents.filter( a => a.firstName + " " + a.lastName === props.selectedAgent)[0] :    {};

    const [currentStep, setCurrentStep ] = useState("List")
    const [name, setName] = useState(props.selectedAgent);
    const [email, setEmail] = useState(agent.email);
    const [phone, setPhone] = useState(agent.phone);
    const [pdfUrl, setPdfUrl] = useState();
    const [loading,setLoading] = useState(false);

    useEffect(() => {
        setName(props.selectedAgent);
        setEmail(agent.email);
        setPhone(agent.phone);


    }, [props.selectedAgent])


    const isDisabled = () => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(String(email).toLowerCase())) {
            return true;
        }

        return !name || !email || !phone;
    };

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.value;
        return value;
    };

    if (loading) return <MiniSection openByDefault title={`Compare (${itemsForComparison.length})`}>
        <Container className={css.panelContainer}>
            <Container className={css.cartPanelContent}>
            <Container className={ css.loading}>
             <Spin/>
            </Container>
            </Container>
        </Container>
    </MiniSection>

    if( currentStep == "List")  { 
        return <MiniSection openByDefault title={`Compare (${itemsForComparison.length})`}>
        <Container className={css.panelContainer}>
            <Container className={css.cartPanelContent}>
                    {itemsForComparison.length == 0 ?
                    <Container className={css.emptyCart}>
                        <Icon icon="compare-plan" fill="#000" />
                        <Container>
                            Add plans for comparison
                        </Container>
                    </Container>

                        : <> {itemsForComparison.map((plan) => {
                        return <CompareMiniCard plan={plan} />
                    })
                    }
                        <Container onPress={() => { setCurrentStep("Contact")}} className={css.enrollButton}><Button name="quotes-panel-btn-compare" type="primary">Compare</Button></Container>
                    </>
                }

            </Container>
        </Container>
    </MiniSection>
    }

    else if (currentStep == "Contact")  {
        return <MiniSection openByDefault title={`Compare (${itemsForComparison.length})`}>
        <Container className={css.panelContainer}>

            <Container className={ css.contactInfo }> Contact Info</Container>
            <Container className={ css.textInput}><TextInput 
                    value={name}
                    onChange={(e) => {
                        setName(handleInputChange(e));
                    }}
            
            placeholder="name" ></TextInput></Container>
            <Container 
             
            
            className={ css.textInput}><TextInput 
                        value={email}
                        onChange={(e) => {
                            setEmail(handleInputChange(e));
                        }}
                        mask={
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        }
            
            placeholder="email" ></TextInput></Container>
            <Container 

            
            
            className={ css.textInput}><TextInput
            
                        value={phone}
                        onChange={(e) => {
                            const x = e.target.value
                                .replace(/\D/g, "")
                                .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
                            e.target.value = !x[2]
                                ? x[1]
                                : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");

                            setPhone(handleInputChange(e));
                        }}
                        mask={/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/}
            
            
            placeholder="phone" ></TextInput></Container>

            <Container onPress={async () => { 

                
                    try { 

                    setLoading(true);

       

                    const { host,search,protocol} = window.location;
                    const response = await axios({
                        method: "post",
                        url: `${baseUrl}/v1/quote-comparison/${props.quote_id}`,
                        data: {
                            agent: {
                                name,
                                email,
                                phone
                            },
                            plans: itemsForComparison.map((p) => { 
                                if(p.carrier.value == "National General") { 
                                    p.product_source = "NATIONAL_GENERAL"
                                    const value = props.specialRates
                                        ? props.specialRates.priorCoverage
                                            ? props.specialRates.prefferedRate
                                                ? p.premium_options.options[2].premium
                                                : p.premium_options.options[1].premium
                                            : p.premium_options.options[0].premium
                                        : p.premium_options.options[0].premium;

                                        p.premium.value = value
                                } else if(p.carrier.value== "Universal") { 
                                    p.product_source = "UNIVERSAL"
                                } else if(p.carrier.value == "Aspire Dental"){
                                    p.product_source = "ASPIRE_DENTAL"
                                } else {
                                    p.product_source = "MARKETPLACE"
                                }

                                p.checkout_url = `${protocol}//${host}/checkout/${search}&quote_id=${props.quote_id}&product_source=${p.product_source}${p.plan_id ? `&plan_id=${p.plan_id.value}`: ""}`
                        

                                if(p.carrier.value == "National General") {
                                    p.checkout_url += `&plan_key=${p.plan_key.value}&insurance_network=${p.insurance_network.value}&has_prior_coverage=${!!props.specialRates?.priorCoverage}&rate_tier=${props.specialRates?.prefferedRate ? "Preferred" : "Standard" }`;
                                }


                                return p;

                            })

                        },
                        headers: {
                            "x-apollo-quotes-source": "ca5efa72-9b40-44af-be61-2d61706b1a2c",
                        },
                    });
                    clearComparisonList();
                    setPdfUrl(response.data.url)
                    setCurrentStep("Result") }
                catch ( ex) {
                    console.log(ex)
                    alert("there was an error")
                } finally {
                    setLoading(false)
                }
                }} className={css.enrollButton}><Button disabled={isDisabled()}name="quotes-panel-btn-compare" type="primary">Generate PDF</Button></Container>

        </Container>
    </MiniSection> 
    } else {
        return <MiniSection title={`Compare (${itemsForComparison.length})`}>
            <Container className={css.panelContainer}>
                <Icon className={ css.successIcon} fill="#72C140" icon="corn-success"/>
                <Container className={ css.thankYouTitle}> Thank You </Container>
                <Container className={css.thankYouSubtitle}> Thank you for submitting a request with My Aspire Quotes! </Container>
                <Container onPress={() => { window.open(pdfUrl); setCurrentStep("List") }} className={css.enrollButton}><Button name="quotes-panel-btn-compare" type="primary">Download PDF</Button></Container>
 
            </Container>
        </MiniSection>
    }
}


const mapStateToProps = ({ baseUrl, specialRates}) => {
    return {
        baseUrl,
        specialRates
    }
}

const mapDispatchToProps = (dispatch) => {
    return { 
        clearComparisonList: (payload) => {
            dispatch({ type: `CLEAR_COMPARISON_LIST`, payload });
        },
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(CompareMiniSection);