import React, { useEffect } from "react";

import { connect } from "react-redux";

import css from "./style.module.scss";

const SearchIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      stroke-width="1.5"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M15.5 15.5L19 19"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 11C5 14.3137 7.68629 17 11 17C12.6597 17 14.1621 16.3261 15.2483 15.237C16.3308 14.1517 17 12.654 17 11C17 7.68629 14.3137 5 11 5C7.68629 5 5 7.68629 5 11Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const SearchInputRaw = (props) => {
  const { categoryKey } = props;

  const item = {
    type: "SEARCH",
    attribute: "input",
  };

  return (
    <div className={css.searchInputContainer}>
      <SearchIcon />
      <input
        placeholder="Search"
        onChange={(e) => {
          props.addFilter({
            categoryKey,
            item,
            value: e.currentTarget.value,
          });
        }}
      />
    </div>
  );
};

const mapStateToProps = ({ filters }) => {
  return {
    filters: { ...filters },
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addFilter: (payload) => dispatch({ type: `ADD_FILTER`, payload }),
    removeFilter: (payload) => dispatch({ type: `REMOVE_FILTER`, payload }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchInputRaw);
