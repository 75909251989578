import axios from "axios";
import React, { useEffect, useRef, useState } from "react";

import css from "./style.module.scss";

interface ModalRawProps {
  isOpen: boolean;
  onClose: () => void;
  zipCode: string;
  handleSelectDoctor: (doctorNpi: string) => void;
  handleChangeDoctor: (searchText: string, doctorName?: string) => void;
  doctorList: any[];
  currentDoctor: string;
}

export const ModalRaw = ({
  isOpen,
  onClose,
  zipCode,
  handleSelectDoctor,
  doctorList,
  handleChangeDoctor,
  currentDoctor,
}: ModalRawProps) => {
  const [doctorResults, setDoctorResults] = useState([]);
  const [doctorText, setDoctorText] = useState("");
  // const doctorTextInput = useRef(null);

  useEffect(() => {
    setDoctorText(currentDoctor);
    setDoctorResults(doctorList);
  }, [doctorList]);

  const findDoctor = async (e: React.FormEvent) => {
    e.preventDefault();

    handleChangeDoctor(doctorText);

    try {
      const response = await axios({
        method: "get",
        url: `https://api.qa.myaspirequotes.com/v1/search-providers/marketplace`,
        params: {
          zip_code: zipCode,
          search_query: doctorText,
        },
      });

      const { providers } = response.data;

      setDoctorResults(providers);
    } catch (err) {
      setDoctorResults([]);
    }
  };

  const onClickSelectButton = (doctorNpi: string, doctorName: string) => {
    handleSelectDoctor(doctorNpi);
    handleChangeDoctor(doctorText, doctorName);
    onClose();
  };

  return (
    <>
      {isOpen && (
        <div className={css.modal}>
          <div className={css.modalOverlay} onClick={() => onClose()} />
          <div className={css.innerModal}>
            <div className={css.header}>
              <form className={css.inputContainer} onSubmit={findDoctor}>
                <SearchIcon />
                <input
                  type="text"
                  placeholder="Enter the name of a doctor, specialist"
                  onChange={(e) => setDoctorText(e.currentTarget.value)}
                  value={doctorText}
                />
                <button type="submit">Find a Doctor</button>
              </form>
              <button className={css.closeButton} onClick={onClose}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  stroke-width="1.5"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M6.75827 17.2426L12.0009 12M17.2435 6.75736L12.0009 12M12.0009 12L6.75827 6.75736M12.0009 12L17.2435 17.2426"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </div>
            <div className={css.cardsWrapper}>
              {doctorResults && doctorResults.length > 0 ? (
                doctorResults.map((doctor) => (
                  <button
                    className={css.cardButton}
                    onClick={() =>
                      onClickSelectButton(
                        doctor.provider.npi,
                        doctor.provider.name
                      )
                    }
                  >
                    <div className={css.innerCard}>
                      <div className={css.cardIcon}>
                        <DoctorIcon />
                      </div>
                      <p className={css.cardTitle}>{doctor.provider.name}</p>
                      <p>{doctor.provider.specialties[0]}</p>
                    </div>
                  </button>
                ))
              ) : (
                <div className={css.noResults}>
                  <div className={css.inner}>
                    <DoctorIcon />
                    <p>
                      Sorry, no results were available for the term you entered
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const SearchIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      stroke-width="1.5"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M15.5 15.5L19 19"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 11C5 14.3137 7.68629 17 11 17C12.6597 17 14.1621 16.3261 15.2483 15.237C16.3308 14.1517 17 12.654 17 11C17 7.68629 14.3137 5 11 5C7.68629 5 5 7.68629 5 11Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const DoctorIcon = () => {
  return (
    <svg
      width="41"
      height="41"
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_3328_808)">
        <path
          d="M29.8986 1.95312H25.4944V4.67738H28.5365V10.7616C28.5365 15.3433 24.8088 19.0706 20.2275 19.0706H19.5918C15.0101 19.0706 11.2829 15.3428 11.2829 10.7616V4.67738H14.3249V1.95312H9.92072C9.16839 1.95312 8.55859 2.56292 8.55859 3.31525V10.7616C8.55859 16.8453 13.5081 21.7948 19.5918 21.7948H20.2275C26.3112 21.7948 31.2607 16.8453 31.2607 10.7616V3.31525C31.2607 2.56292 30.6509 1.95312 29.8986 1.95312Z"
          fill="#004C64"
        />
        <path
          d="M25.3582 0C24.6059 0 23.9961 0.609795 23.9961 1.36213V5.17609C23.9961 5.92842 24.6059 6.53822 25.3582 6.53822C26.1106 6.53822 26.7204 5.92842 26.7204 5.17609V1.36213C26.7204 0.609795 26.1106 0 25.3582 0Z"
          fill="#004C64"
        />
        <path
          d="M14.5965 0C13.8442 0 13.2344 0.609795 13.2344 1.36213V5.17609C13.2344 5.92842 13.8442 6.53822 14.5965 6.53822C15.3488 6.53822 15.9586 5.92842 15.9586 5.17609V1.36213C15.9586 0.609795 15.3488 0 14.5965 0Z"
          fill="#004C64"
        />
        <path
          d="M21.363 28.1054V20.3867H18.6387V28.1054H14.2345C10.6794 28.1054 7.78711 30.9977 7.78711 34.5524C7.78711 38.1075 10.6998 40.9998 14.2799 40.9998H14.9156C18.4707 40.9998 21.363 38.1075 21.363 34.5524V30.8297H25.9942V28.1054H21.363ZM18.6387 34.5519C18.6387 36.6046 16.9683 38.275 14.9156 38.275H14.2799C12.1668 38.275 10.5114 36.64 10.5114 34.5519C10.5114 32.4992 12.1818 30.8292 14.2345 30.8292H18.6387V34.5519Z"
          fill="#004C64"
        />
        <path
          d="M28.9453 25.1992C26.5924 25.1992 24.6777 27.1135 24.6777 29.4672C24.6777 31.8201 26.592 33.7347 28.9453 33.7347C31.2986 33.7347 33.2133 31.8201 33.2133 29.4672C33.2133 27.1139 31.299 25.1992 28.9453 25.1992ZM28.9453 31.0101C28.0943 31.0101 27.402 30.3177 27.402 29.4668C27.402 28.6155 28.0944 27.9231 28.9453 27.9231C29.7966 27.9231 30.489 28.6155 30.489 29.4668C30.489 30.3177 29.7966 31.0101 28.9453 31.0101Z"
          fill="#004C64"
        />
      </g>
      <defs>
        <clipPath id="clip0_3328_808">
          <rect width="41" height="41" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
