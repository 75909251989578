import React from "react";
import { Avatar, Container, Logo, MenuMobile } from "../../components";
import css from "./style.module.scss";

import { connect } from "react-redux";

const Header = (props) => {
  const { data } = props;

  return (
    <header className={css.mainContainer}>
      <MenuMobile />
      <Container className={css.logoContainer}>
        <Container className={css.logoContainer}>
          <Container className={css.logo}>
            <a href="/">
              <img src="/maq_logo_black.svg" alt="MyAspireQuotes Logo" />
            </a>
          </Container>
        </Container>
      </Container>

      <Container className={css.agentName}>
        {false && data && data.site.siteMetadata.agent.name}
      </Container>

      <Container className={css.agentTitle}>
        {data && data.site.siteMetadata.agent.title}
      </Container>
      <Container className={css.bioButton}>View Bio</Container>
      <Container className={css.headerRight}>
        <Container className={css.menuContainer}></Container>
      </Container>
      <Container className={css.avatarContainer}>
        <Avatar
          className={css.avatar}
          link=""
          round="true"
          initials={
            props.selectedAgent
              ? props.selectedAgent[0] + props.selectedAgent.split(" ")[1][0]
              : undefined
          }
        />
      </Container>
    </header>
  );
};

const mapStateToProps = ({ selectedAgent }) => {
  return {
    selectedAgent,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedAgent: (payload) =>
      dispatch({ type: `SET_SELECTED_AGENT`, payload }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
