import React from "react";
import css from "./style.module.scss";

import { Container, Header } from "..";

const MasterPage = ({ children, data }) => {
  return (
    <Container className={css.mainContainer}>
      {true && <Header data={data} siteTitle={`My Aspire Quotes`} />}
      <main className={css.contentContainer}>{children}</main>
    </Container>
  );
};

export default MasterPage;
